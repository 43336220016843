export const dictionary = {
    login: {
        pl: 'Logowanie',
        cs: 'Přihlášení',
        sk: 'Prihlásenie',
        en: 'Log in',
        hu: 'Log in',
        ro: 'Logare',
    },
    register: {
        pl: 'Rejestracja',
        cs: 'Registrace',
        sk: 'Registrácia',
        en: 'Register',
        hu: 'Register',
        ro: 'Înregistrare',
    },
    username: {
        pl: 'nazwa użytkownika',
        cs: 'uživatelské jméno',
        sk: 'uživatelské jméno',
        en: 'user name',
        hu: 'user name',
        ro: 'nume de utilizator',
    },
    orderCourse: {
        pl: 'Zamów kurs',
        cs: 'Order a course',
        sk: 'Order a course',
        en: 'Order a course',
        hu: 'Order a course',
        ro: 'Comandați un curs',
    },
    aboutVerbalia: {
        pl: 'O Verbalia',
        cs: 'About Verbalia',
        sk: 'About Verbalia',
        en: 'About Verbalia',
        hu: 'About Verbalia',
        ro: 'Despre Verbalia',
    },
    aboutUs: {
        pl: 'O nas',
        cs: 'About us',
        sk: 'About us',
        en: 'About us',
        hu: 'About us',
        ro: 'Despre noi',
    },
    check: {
        pl: 'Sprawdź',
        cs: 'Podívejte se',
        sk: 'Skontrolujte',
        en: 'Check',
        hu: 'Jelölje be',
        ro: 'Verifica',
    },
    homePageSloganFirstLine: {
        pl: 'Nauka nowego języka?',
        cs: 'Učíte se cizí jazyk?',
        sk: 'Objavujete nový jazyk?',
        en: 'Discovering a new language?',
        hu: 'Discovering a new language?',
        ro: 'Descoperiți o nouă limbă?',
    },
    homePageSloganSecondLine: {
        pl: 'To tylko 2 kroki!',
        cs: 'Stačí jen 2 kroky!',
        sk: 'Stačia na to len 2 kroky!',
        en: 'It only takes 2 steps!',
        hu: 'It only takes 2 steps!',
        ro: 'Este nevoie doar de 2 pași!',
    },
    loginBoxDescription: {
        pl: 'Masz już konto na platformie i chcesz rozpocząć naukę?  Twoja przygoda z językiem zaczyna się właśnie tutaj!',
        cs: 'Již máte na platformě účet a chcete se začít učit?  Vaše jazyková dobrodružství právě začínají!',
        sk: 'Už máte konto na platforme a chcete sa začať učiť?  Vaše jazykové dobrodružstvo sa začína práve tu!',
        en: 'Already have an account on the platform and want to begin learning?  Your language adventure starts right here!',
        hu: 'Already have an account on the platform and want to begin learning?  Your language adventure starts right here!',
        ro: 'Aveți deja un cont pe platformă și doriți să începeți să învățați?  Aventura ta lingvistică începe chiar aici!',
    },
    registerBoxDescription: {
        pl: 'Masz już kod do rejestracji? Świetnie! W tym miejscu możesz założyć konto!',
        cs: 'Máte již registrační kód? Skvělé! Nyní si zde můžete vytvořit účet!',
        sk: 'Máte už registračný kód? Skvelé! Tu si môžete vytvoriť účet!',
        en: 'Do you already have a registration code? Great! Here you can create an account!',
        hu: "You've already spoken to one of our language consultants and received your package with a code? Great! Here you can create an account!",
        ro: 'Ați vorbit deja cu unul dintre consultanții noștri lingvistici și ați primit pachetul cu un cod? Minunat! Aici vă puteți crea un cont!',
    },
    learnMoreHeader: {
        pl: 'Chcesz dowiedzieć się więcej?',
        cs: 'Chtěli byste se naučit více?',
        sk: 'Chcete sa naučiť viac?',
        en: 'Would you like to learn more?',
        hu: 'Would you like to learn more?',
        ro: 'Doriți să învățați mai mult?',
    },
    learnMoreDescription: {
        pl: 'Czym jest Verbalia? Co myślą o nim kursanci? Dlaczego warto?',
        cs: 'Co je Verbalia? Jaké jsou názory studentů? Proč to stojí za to?',
        sk: 'Čo je to Verbalia? Čo si o ňom myslia používatelia kurzov? Prečo sa oplatí?',
        en: 'What is Verbalia? What do course users think about it? Why is it worth it?',
        hu: 'What is Verbalia? What do course users think about it? Why is it worth it?',
        ro: 'Ce înseamnă poliglot? Ce părere au utilizatorii de cursuri despre el? De ce merită?',
    },
    learnMoreButton: {
        pl: 'więcej',
        cs: 'více',
        sk: 'viac',
        en: 'more',
        hu: 'more',
        ro: 'mai multe',
    },
    mainPageWelcomeBoxHeaderOne: {
        pl: 'Cześć',
        cs: 'Ahoj',
        sk: 'Ahoj',
        en: 'Hi',
        hu: 'Hi',
        ro: 'Bună ziua',
    },
    mainPageWelcomeBoxHeaderTwo1: {
        pl: 'Świetnie Ci idzie',
        cs: 'Vedeš si skvěle!',
        sk: 'Darí sa ti skvele',
        en: "You're doing great",
        hu: "You're doing great",
        ro: 'Te descurci foarte bine',
    },
    mainPageWelcomeBoxHeaderTwo2: {
        pl: 'Niezła robota',
        cs: 'Dobrá práce',
        sk: 'Dobrá práca',
        en: 'Good job',
        hu: 'Good job',
        ro: 'Bună treabă',
    },
    mainPageWelcomeBoxHeaderTwo3: {
        pl: 'Rozwijasz się',
        cs: 'Zlepšuješ se',
        sk: 'Zlepšujete sa',
        en: "You're improving",
        hu: "You're improving",
        ro: 'Te îmbunătățești.',
    },
    mainPageWelcomeBoxTextFirstLine1: {
        pl: 'Znajomość języków obcych jest nie tylko przydatna, ale wręcz niezbędna do osiągnięcia sukcesu w życiu.',
        cs: 'Znalost cizích jazyků je nejen užitečná, ale také nezbytná k dosažení životního úspěchu.',
        sk: 'Znalosť cudzích jazykov je nielen užitočná, ale aj nevyhnutná pre úspech v živote.',
        en: 'Knowledge of foreign languages is not only useful, but actually necessary for success in life.',
        hu: 'Knowledge of foreign languages is not only useful, but actually necessary for success in life.',
        ro: 'Cunoașterea limbilor străine nu este doar utilă, ci chiar necesară pentru a avea succes în viață.',
    },
    mainPageWelcomeBoxTextFirstLine2: {
        pl: 'Osoby, które mówią w więcej niż 1 języku, mają lepszą pamięć, lepszą koncentrację, zdolność do wielozadaniowości i lepsze umiejętności słuchania.',
        cs: 'Lidé, kteří mluví více než jedním jazykem, mají lepší paměť, lepší soustředění, schopnost multitaskingu a lepší schopnost naslouchat.',
        sk: 'Ľudia, ktorí ovládajú viac ako jeden jazyk, majú lepšiu pamäť, lepšiu koncentráciu, schopnosť vykonávať viac úloh naraz a lepšie počúvajú.',
        en: 'People who speak more than one language have improved memory, enhanced concentration, ability to multitask, and better listening skills.',
        hu: 'People who speak more than one language have improved memory, enhanced concentration, ability to multitask, and better listening skills.',
        ro: 'Persoanele care vorbesc mai multe limbi străine au o memorie îmbunătățită, o concentrare sporită, capacitatea de a face mai multe lucruri în același timp și abilități de ascultare mai bune.',
    },
    mainPageWelcomeBoxTextFirstLine3: {
        pl: 'Nauka języka oznacza wyjście poza swoją strefę komfortu. Plusem jest niesamowite poczucie spełnienia, które poczujesz, rozmawiając z kimś w jego ojczystym języku.',
        cs: 'Naučit se cizí jazyk si vyžaduje pevnou vůli. Výhodou je úžasný pocit úspěchu, který pocítíte při konverzaci s někým v jeho rodném jazyce.',
        sk: 'Učiť sa cudzí jazyk znamená vystúpiť zo svojej komfortnej zóny. Pozitívom je úžasný pocit úspechu, ktorý pocítite, keď sa s niekým rozprávate v jeho rodnom jazyku.',
        en: 'Learning a language means moving out of your comfort zone. The upside is the amazing sense of accomplishment you’ll feel when conversing with someone in their native language.',
        hu: 'Learning a language means moving out of your comfort zone. The upside is the amazing sense of accomplishment you’ll feel when conversing with someone in their native language.',
        ro: 'Învățarea unei limbi străine înseamnă să ieși din zona ta de confort. Partea bună este sentimentul uimitor de realizare pe care îl veți simți atunci când veți conversa cu cineva în limba sa maternă.',
    },
    mainPageWelcomeBoxTextSecondLine: {
        pl: 'W następnej lekcji nauczysz się:',
        cs: 'V příští lekci se naučíte:',
        sk: 'V nasledujúcej lekcii sa naučíte:',
        en: 'In the next lesson you will learn:',
        hu: 'In the next lesson you will learn:',
        ro: 'În următoarea lecție veți învăța:',
    },
    yourActivityBoxHeader: {
        pl: 'Twoja aktywność',
        cs: 'Tvoje aktivita',
        sk: 'Vaša aktivita',
        en: 'Your activity',
        hu: 'Your activity',
        ro: 'Activitatea ta',
    },
    yourActivityBoxDaysOfLearning: {
        pl: 'dni nauki z rzędu',
        cs: 'Dny učení v řadě',
        sk: 'Dni učenia v rade',
        en: 'days of learning in a row',
        hu: 'days of learning in a row',
        ro: 'zile de învățare la rând',
    },
    yourActivityBoxLearnedWords: {
        pl: 'poznanych dziś słów',
        cs: 'Nová slovíčka za dnešek',
        sk: 'slová, ktoré ste sa dnes naučili',
        en: 'the words you learned today',
        hu: 'the words you learned today',
        ro: 'cuvintele pe care le-ați învățat astăzi',
    },
    level: {
        pl: 'Poziom',
        cs: 'Úroveň',
        sk: 'Úroveň',
        en: 'Level',
        hu: 'Level',
        ro: 'Nivelul',
    },
    mainPageProgressBarDescription: {
        pl: 'Twój postęp na poziomie',
        cs: 'Váš pokrok na úrovni',
        sk: 'Váš pokrok na úrovni',
        en: 'Your progress on level',
        hu: 'Your progress on level',
        ro: 'Progresul tău la nivel',
    },
    mainPageContinueLearningButtonText: {
        pl: 'Kontynuuj naukę',
        cs: 'Pokračovat v učení',
        sk: 'Pokračujte v učení',
        en: 'Continue learning',
        hu: 'Continue learning',
        ro: 'Continuați să învățați',
    },
    mainPageAllLessonsgButtontext: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        en: 'All lessons',
        hu: 'All lessons',
        ro: 'Toate lecțiile',
    },
    mainPageActiveCourseHeader: {
        pl: 'Twój aktualny kurs',
        cs: 'Tvůj aktuální kurz',
        sk: 'Tvoj aktuálny kurz',
        en: 'Active course',
        hu: 'Aktuális kurzusod',
        ro: 'Cursul tău actual',
    },
    mainPageOtherCoursesButtonTextPre: {
        pl: 'Chcesz nauczyć się więcej?',
        cs: 'Chceš se naučit více?',
        sk: 'Chceš sa naučiť viac?',
        en: 'Want to learn more?',
        hu: 'Szeretnél többet megtanulni?',
        ro: 'Vrei săînveți mai mult?',
    },
    mainPageOtherCoursesButtonText: {
        pl: 'Aktywuj kolejny kurs >>',
        cs: 'Aktivuj další kurz >>',
        sk: 'Aktivuj ďalší kurz >>',
        en: 'Other Courses',
        hu: 'Aktiváld a következő kurzust >>',
        ro: 'Activează un alt curs >>',
    },
    mainPageFunFactsHeader: {
        pl: 'Ciekawostki',
        cs: 'Zajímavá fakta',
        sk: 'Zaujímavosti',
        en: 'Interesting facts',
        hu: 'Interesting facts',
        ro: 'Date interesante',
    },
    showPassword: {
        pl: 'Pokaż hasło',
        cs: 'Zobrazit heslo',
        sk: 'Ukázať heslo',
        en: 'Show password',
        hu: 'Mutasd a jelszót',
        ro: 'Afișați parola',
    },
    hidePassword: {
        pl: 'Ukryj hasło',
        cs: 'Skrýt heslo',
        sk: 'Skryť heslo',
        en: 'Hide password',
        hu: 'Jelszó elrejtése',
        ro: 'Ascundeți parola',
    },
    passwordsNotMatchAlert: {
        pl: 'Hasła nie są takie same',
        cs: 'Hesla nejsou stejná',
        sk: 'Heslá sa nezhodujú',
        en: 'Passwords do not match',
        hu: 'A jelszavak nem egyeznek',
        ro: 'Parolele nu sunt la fel',
    },
    progressModalMondayShort: {
        pl: 'pn.',
        cs: 'Po.',
        sk: 'Po,',
        en: 'Mon.',
        hu: 'Hé',
        ro: 'Luni',
    },
    progressModalTuesdayShort: {
        pl: 'wt.',
        cs: 'Út.',
        sk: 'Ut.',
        en: 'Tue.',
        hu: 'Ke',
        ro: 'Marți',
    },
    progressModalWednesdayShort: {
        pl: 'śr.',
        cs: 'St.',
        sk: 'St.',
        en: 'Wed.',
        hu: 'Sze',
        ro: 'Miercuri',
    },
    progressModalThursdayShort: {
        pl: 'czw.',
        cs: 'Čt.',
        sk: 'Stv.',
        en: 'Thu.',
        hu: 'Cs',
        ro: 'Joi',
    },
    progressModalFridayShort: {
        pl: 'pt.',
        cs: 'Pá.',
        sk: 'Pi.',
        en: 'Fri.',
        hu: 'Pé',
        ro: 'Vineri',
    },
    progressModalSaturdayShort: {
        pl: 'sob.',
        cs: 'So.',
        sk: 'So.',
        en: 'Sat.',
        hu: 'Szo',
        ro: 'Sâmbătă',
    },
    progressModalSundayShort: {
        pl: 'ndz.',
        cs: 'Ne.',
        sk: 'Ne.',
        en: 'Sun',
        hu: 'Vas',
        ro: 'Duminică',
    },
    progressModalChartTitle: {
        pl: 'Wykres Twoich postępów',
        cs: 'Graf vašeho pokroku',
        sk: 'Graf vášho pokroku',
        en: 'Charting your progress',
        hu: 'A fejlődés feltérképezése',
        ro: 'Graficul tău de progres',
    },
    back: {
        pl: 'Wstecz',
        cs: 'Zpět',
        sk: 'Späť',
        en: 'Back',
        hu: 'Vissza',
        ro: 'Înapoi',
    },
    emailLabel: {
        pl: 'adres e-mail',
        cs: 'e-mailová adresa',
        sk: 'emailová adresa',
        en: 'email address',
        hu: 'email cím',
        ro: 'adresa de e-mail',
    },
    passwordLabel: {
        pl: 'hasło',
        cs: 'heslo',
        sk: 'heslo',
        en: 'password',
        hu: 'jelszó',
        ro: 'parola',
    },
    activationCodeLabel: {
        pl: 'kod',
        cs: 'kód',
        sk: 'kód',
        en: 'code',
        hu: 'kód',
        ro: 'cod',
    },
    repeatPasswordLabel: {
        pl: 'powtórz hasło',
        cs: 'zopakovat heslo',
        sk: 'zopakujte heslo',
        en: 'repeat password',
        hu: 'jelszó újra',
        ro: 'repetați parola',
    },
    oldPasswordLabel: {
        pl: 'stare hasło',
        cs: 'staré heslo',
        sk: 'staré heslo',
        en: 'old password',
        hu: '',
        ro: 'parola veche',
    },
    registerEmailDesc: {
        pl: 'Twój adres e-mail, którego będziesz używał/a do zalogowania się',
        cs: 'Vaše e-mailová adresa, kterou budete používat pro přihlášení',
        sk: 'Vaša e-mailová adresa, ktorú budete používať na prihlásenie',
        en: 'Your e-mail address, which you will use to log in',
        hu: 'az Ön e-mail címe, amelyet a bejelentkezéshez fog használni',
        ro: 'Adresa dvs. de e-mail pe care o veți folosi pentru a vă conecta',
    },
    loginEmailDesc: {
        pl: 'Twój adres e-mail, który podawałeś/aś przy założeniu konta',
        cs: 'Vaše e-mailová adresa, kterou jste zadali při vytváření účtu',
        sk: 'Vaša e-mailová adresa, ktorú ste uviedli pri vytváraní účtu',
        en: 'Your email address you entered when you created account',
        hu: 'A fiók létrehozásakor megadott e-mail címed',
        ro: 'Adresa dvs. de e-mail pe care ați furnizat-o la crearea contului dvs.',
    },
    activationCodeDesc: {
        pl: 'otrzymałeś/aś go w liście',
        cs: 'obdrželi jste ho v dopise',
        sk: 'dostali ste to v liste',
        en: 'you have received it in the letter',
        hu: 'evélben kaptad',
        ro: 'ați primit-o prin poștă',
    },
    registerPasswordDesc: {
        pl: 'stwórz swoje unikalne hasło, które łatwo zapamiętasz',
        cs: 'vytvořte si vlastní jedinečné heslo, které si snadno zapamatujete',
        sk: 'vytvorte si svoje jedinečné heslo, ktoré si ľahko zapamätáte',
        en: 'create your own unique password that you can easily remember',
        hu: 'hozzon létre saját egyedi jelszót, amelyet könnyen megjegyezhet',
        ro: 'creați o parolă unică pe care să o puteți reține cu ușurință',
    },
    repeatPasswordDesc: {
        pl: 'aby upewnić się, że pamiętasz hasło, wpisz je jeszcze raz',
        cs: 'abyste se ujistili, že si heslo pamatujete, zadejte ho znovu',
        sk: 'aby ste sa uistili, že si zapamätáte svoje heslo, zadajte ho znova',
        en: 'to make sure you remember your password, type it again',
        hu: 'hogy biztosan emlékezzen a jelszavára, írja be újra',
        ro: 'pentru a vă asigura că vă amintiți parola, introduceți-o din nou',
    },
    loginPasswordDesc: {
        pl: 'Twoje unikalne hasło, które stworzyłeś/aś podczas zakładania konta',
        cs: 'Vaše jedinečné heslo, které jste si vytvořili při vytváření účtu',
        sk: 'Vaše jedinečné heslo, ktoré ste si vytvorili pri vytváraní účtu',
        en: 'Your unique password that you created when you created account',
        hu: 'Az Ön egyedi jelszava, amelyet a fiók létrehozásakor hozott létre',
        ro: 'Parola unică pe care ați creat-o atunci când v-ați creat contul dvs.',
    },
    loginButtonText: {
        pl: 'Zaloguj się',
        cs: 'Přihlásit se',
        sk: 'Prihláste sa',
        en: 'Log in',
        hu: 'Bejelentkezés',
        ro: 'Conectați-vă',
    },
    logoutButtonText: {
        pl: 'Wyloguj się',
        cs: 'Odhlásit se',
        sk: 'Odhlásiť sa',
        en: 'Log out',
        hu: 'Kijelentkezés',
        ro: 'Deconectați-vă',
    },
    logoutMessage: {
        pl: 'Czy na pewno chcesz się wylogować?',
        cs: 'Opravdu se chcete odhlásit?',
        sk: 'Naozaj sa chcete odhlásiť?',
        en: 'Are you sure you want to log out?',
        hu: '',
        ro: 'Sunteți sigur că doriți să vă deconectați?',
    },
    registerSlogan: {
        pl: 'Aby utworzyć konto i mieć dostęp do nauki wykupionego kursu językowego, wypełnij poniższe pola:',
        cs: 'Pro vytvoření účtu a přístup k zakoupenému jazykovému kurzu vyplňte níže uvedená pole:',
        sk: 'Ak chcete vytvoriť účet a získať prístup k štúdiu jazykového kurzu, ktorý ste si zakúpili, vyplňte polia nižšie:',
        en: 'Fill in the fields below to create an account and have access to your language course:',
        hu: 'Töltse ki az alábbi mezőket a fiók létrehozásához és a nyelvtanfolyamhoz való hozzáféréshez:',
        ro: 'Pentru a vă crea un cont și a avea acces pentru a studia cursul de limbă achiziționat, completați câmpurile de mai jos:',
    },
    loginSlogan: {
        pl: 'Jeśli posiadasz już konto, wypełnij poniższe pola:',
        cs: 'Pokud již máte účet, vyplňte níže uvedená pole:',
        sk: 'Ak už máte účet, vyplňte polia nižšie:',
        en: 'If you already have an account, please fill out the fields below:',
        hu: 'Ha már rendelkezik fiókkal, kérjük, töltse ki az alábbi mezőket:',
        ro: 'Dacă aveți deja un cont, completați câmpurile de mai jos:',
    },
    registerButtonText: {
        pl: 'Załóż konto',
        cs: 'Vytvořit účet',
        sk: 'Vytvoriť účet',
        en: 'Create an account',
        hu: 'HOZZON LÉTRE EGY FIÓKOT',
        ro: 'Creați un cont',
    },
    levelOneText: {
        pl: 'podstawowy',
        cs: 'základní',
        sk: 'ZÁKLADNÉ',
        en: 'Basic',
        hu: 'Alapvető',
        ro: 'de bază',
    },
    levelTwoText: {
        pl: 'średni',
        cs: 'střední',
        sk: 'STREDNE',
        en: 'Medium',
        hu: 'Közepes',
        ro: 'intermediar',
    },
    levelThreeText: {
        pl: 'zaawansowany',
        cs: 'pokročilý',
        sk: 'POKROČILÉ',
        en: 'Advanced',
        hu: 'Fejlett',
        ro: 'avansat',
    },
    pageTitle: {
        pl: 'Verbalia',
        cs: 'Verbalia',
        sk: 'Verbalia',
        en: 'Verbalia',
        hu: 'Verbalia',
        ro: 'Verbalia',
    },
    activityExpressionLearning: {
        pl: 'Poznaj słowo',
        cs: 'Zkoumej slovo',
        sk: 'Objavte slovo',
        en: 'Discover the word',
        hu: 'Discover the word',
        ro: 'Descoperiți cuvântul',
    },
    activityExpressionListening: {
        pl: 'Zaznacz, co słyszysz',
        cs: 'Vyber, co slyšíš',
        sk: 'Vyberte, čo počujete',
        en: 'Mark what you hear',
        hu: 'Mark what you hear',
        ro: 'Marcați ceea ce auziți',
    },
    activityExpressionTranslation: {
        pl: 'Ułóż z wyrazów poprawne tłumaczenie',
        cs: 'Použijte slova a správně přeložte',
        sk: 'Použite slová na vytvorenie správneho prekladu',
        en: 'Make the correct translation from the words',
        hu: 'Make the correct translation from the words',
        ro: 'Faceți traducerea corectă din cuvinte',
    },
    activityUnscrambleExpressionLetters: {
        pl: 'Ułóż z liter poprawne tłumaczenie',
        cs: 'Použijte písmena a správně přeložte',
        sk: 'Použite písmená na vytvorenie správneho prekladu',
        en: 'Use letters to make the correct translation',
        hu: 'Use letters to make the correct translation',
        ro: 'Folosiți literele pentru a face traducerea corectă',
    },
    activityLinkExpressions: {
        pl: 'Połącz w pary',
        cs: 'Párová slova',
        sk: 'Vytvorte dvojice slov',
        en: 'Put them together',
        hu: 'Put them together',
        ro: 'Puneți-le împreună',
    },
    exampleSentence: {
        pl: 'Przykładowe zdanie',
        cs: 'Příklad věty',
        sk: 'Príklad vety',
        en: 'Example sentence',
        hu: 'Example sentence',
        ro: 'Exemplu de propoziție',
    },
    yourActivity: {
        pl: 'Twoja aktywność',
        cs: 'Tvoje aktivita',
        sk: 'Vaša aktivita',
        en: 'Your activity',
        hu: 'Your activity',
        ro: 'Activitatea ta',
    },
    tutorial: {
        pl: 'Samouczek',
        cs: 'Výukový kurz',
        sk: 'Učebnica',
        en: 'Tutorial',
        hu: 'Tutorial',
        ro: 'Tutorial',
    },
    changePassword: {
        pl: 'Zmień hasło',
        cs: 'Změna hesla',
        sk: 'Zmena hesla',
        en: 'Change Password',
        hu: 'Change Password',
        ro: 'Schimbați parola',
    },
    logOut: {
        pl: 'Wyloguj się',
        cs: 'Odhlásit se',
        sk: 'Odhlásiť sa',
        en: 'Log out',
        hu: 'Log out',
        ro: 'Deconectați-vă',
    },
    help: {
        pl: 'Pomoc',
        cs: 'Nápověda',
        sk: 'Pomoc',
        en: 'Help',
        hu: 'Help',
        ro: 'Ajutor',
    },
    goodAnswer: {
        pl: 'Dobrze',
        cs: 'Dobře',
        sk: 'Dobrý',
        en: 'Good',
        hu: 'Good',
        ro: 'Bun',
    },
    badAnswer: {
        pl: 'Źle',
        cs: 'Špatně',
        sk: 'Zlé',
        en: 'Bad',
        hu: 'Bad',
        ro: 'Rău',
    },
    continueTasks: {
        pl: 'Dalej',
        cs: 'Další',
        sk: 'Pokračovať',
        en: 'Continue',
        hu: 'Continue',
        ro: 'Continuați',
    },
    lessonCompleted: {
        pl: 'Ukończone',
        cs: 'Dokončeno',
        sk: 'Dokončené',
        en: 'Completed',
        hu: 'Completed',
        ro: 'Completat',
    },
    lessonInProgress: {
        pl: 'w trakcie',
        cs: 'Probíhá',
        sk: 'prebieha',
        en: 'in progress',
        hu: 'in progress',
        ro: 'în curs de desfășurare',
    },
    lessonNotActive: {
        pl: 'niekatywne',
        cs: 'Není aktivní',
        sk: 'nie je aktívny',
        en: 'not active',
        hu: 'not active',
        ro: 'neactiv',
    },
    minutes: {
        pl: 'minut',
        cs: 'minut',
        sk: 'minút',
        en: 'minutes',
        hu: 'minutes',
        ro: 'minute',
    },
    languageChangeYourCourses: {
        pl: 'Twoje kursy',
        cs: 'Vaše kurzy',
        sk: 'Vaše kurzy',
        en: 'Your courses',
        hu: '',
        ro: 'Cursurile dumneavoastră',
    },
    languageChangeAvailable: {
        pl: 'Rozszerz swoje umiejętności językowe:',
        cs: 'Rozšiř své jazykové dovednosti:',
        sk: 'Rozšír svoje jazykové schopnosti:',
        en: 'Also available:',
        hu: 'Fejleszd nyelvi készségeidet:',
        ro: 'Îmbunătățește-țiabilitățile lingvistice:',
    },
    languageActivationInputLabel: {
        pl: 'Wprowadź kod, aby aktywować nowy język:',
        cs: 'Zadejte kód pro aktivaci nového jazyka:',
        sk: 'Zadaním kódu aktivujete nový jazyk:',
        en: '',
        hu: '',
        ro: 'Introduceți codul pentru a activa noua limbă:',
    },
    languageActivationButtonText: {
        pl: 'aktywuj',
        cs: 'aktivace',
        sk: 'aktivácia',
        en: '',
        hu: '',
        ro: 'activați',
    },
    languageActivationInfoFirst: {
        pl: 'Nie masz kodu?',
        cs: 'Nemáte kód?',
        sk: 'Nemáte kód?',
        en: '',
        hu: '',
        ro: 'Nu aveți un cod?',
    },
    languageActivationInfoSecond: {
        pl: 'Napisz do nas na maila: kontakt@verbalia.eu',
        cs: 'Napište nám na adresu: kzs@verbalia.eu',
        sk: 'Napíšte nám na: ksz@verbalia.eu',
        en: '',
        hu: '',
        ro: 'Scrieți-ne pe e-mail: bsc@verbalia.eu',
    },
    contactText: {
        pl: 'W razie jakichkolwiek problemów prosimy o kontakt:',
        cs: 'V případě jakýchkoli problémů se prosím obraťte na:',
        sk: 'V prípade akýchkoľvek problémov nás prosím kontaktujte:',
        en: 'If you have any problems please contact us:',
        hu: 'Ha bármilyen problémája van, forduljon hozzánk:',
        ro: 'Dacă aveți probleme, vă rugăm să ne contactați:',
    },
    contactEmail: {
        pl: 'ksz@verbalia.eu',
        cs: 'kzs@verbalia.eu',
        sk: 'ksz@verbalia.eu',
        en: 'ksz@verbalia.eu',
        hu: 'ksz@verbalia.eu',
        ro: 'bsc@verbalia.eu',
    },
    certButtonText: {
        pl: 'Certyfikat',
        cs: 'Certifikát',
        sk: 'Certifikát',
        en: 'Certificate',
        hu: '',
        ro: 'Certificat',
    },
    certText: {
        pl: 'Ukończyłeś cały kurs i chcesz uzyskać certyfikat? Napisz do nas na adres:',
        cs: 'Absolvovali jste celý kurz a chcete získat certifikát? Napište nám na adresu:',
        sk: 'Absolvovali ste celý kurz a chcete získať certifikát? Napíšte nám na:',
        en: 'Have you completed the entire course and want to get a certificate? Write to us at:',
        hu: '',
        ro: 'Ați finalizat întregul curs și doriți să obțineți un certificat? Scrieți-ne la:',
    },
    certEmail: {
        pl: 'bok@verbalia.eu',
        cs: 'kzs@verbalia.eu',
        sk: 'ksz@verbalia.eu',
        en: 'csc@verbalia.eu',
        hu: '',
        ro: 'bsc@verbalia.eu',
    },
    properAnswer: {
        pl: 'Poprawna odpowiedź:',
        cs: 'Správná odpověď:',
        sk: 'Správna odpoveď:',
        en: 'Proper answer:',
        hu: '',
        ro: 'Răspuns corect:',
    },
    lessonModalHeader: {
        pl: 'Świetnie!',
        cs: 'Skvělé!',
        sk: 'Skvelé!',
        en: 'Great!',
        hu: '',
        ro: 'Foarte bine!',
    },
    lessonModalText: {
        pl: 'Ukończyłeś lekcję',
        cs: 'Lekci jste dokončili',
        sk: 'Ukončili ste lekciu',
        en: 'You have completed the lesson',
        hu: '',
        ro: 'Ați finalizat lecția',
    },
    lessonModalNextLessonText: {
        pl: 'Dalej',
        cs: 'Další',
        sk: 'Ďalej',
        en: 'Next',
        hu: '',
        ro: 'Următorul',
    },
    lessonModalToAllLessonText: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        en: 'All lessons',
        hu: '',
        ro: 'Toate lecțiile',
    },

    tutorialFirstPointText: {
        pl: 'Zawsze rozpoczynaj naukę klikając w duży przycisk "Pokracovat v uceni". To takie proste!',
        cs: 'Učení vždy začněte kliknutím na velké tlačítko "Pokracovat v uceni". Je to tak jednoduché!',
        sk: 'Učenie vždy začnite kliknutím na veľké tlačidlo "Pokračovať v učení". Je to tak jednoduché!',
        ro: 'Întotdeauna începe să înveți apasând pe butonul mare „Continuați să învățați  ”. Acest lucru e atât de simplu!',
    },

    tutorialSecondPointText: {
        pl: 'Chcesz zapoznać się z innymi lekcjami? Po klinknięciu w "Vsechny lekce" możesz zapoznać się ze szczegółowym planem.',
        cs: 'Chcete se seznámit s dalšími lekcemi? Po kliknutí na "Vsechny lekce" si můžete prohlédnout podrobný jízdní řád.',
        sk: 'Chcete sa naučiť ďalšie lekcie? Kliknutím na "Všetky lekcie" zobrazíte podrobný rozvrh.',
        ro: 'Dorești să cunoști alte lecții? După ce ai apăsat pe buton „Toate lecțiile ” poți vedea planul detaliat.',
    },

    tutorialThirdPointText: {
        pl: 'W tym miejscu poznasz dokładny plan lekcji. Jeśli chcesz, możesz tu zmienić poziom nauki, a także zapoznać się z dodatkowymi materiałami.',
        cs: 'Zde najdete přesný jízdní řád. Pokud si přejete, můžete zde změnit úroveň lekcí a také si prohlédnout další materiály. ',
        sk: 'Tu nájdete presný rozvrh. Ak chcete, môžete si tu zmeniť úroveň lekcií a prezrieť si ďalšie materiály.',
        ro: 'În acest loc vei afla orarul exact lecțiilor. Dacă dorești, poți modifica aici nivelul de învățământ, totodată vei putea observa materiale suplimentare.',
    },

    tutorialFourthPointText: {
        pl: 'Chcesz zmienić poziom nauki? Zadania są dla Ciebie zbyt proste, albo zbyt trudne? Wystarczy, że klikniesz w wybrany przez siebie poziom w tym miejscu.',
        cs: 'Chcete změnit úroveň učení? Jsou pro vás úkoly příliš snadné nebo příliš obtížné? Stačí kliknout na vybranou úroveň.',
        sk: 'Chcete zmeniť úroveň vzdelávania? Sú pre vás úlohy príliš ľahké alebo príliš ťažké? Stačí kliknúť na vybranú úroveň.',
        ro: 'Ai dori să schimbi nivelul de învățământ? Sarcinile sunt prea simple sau prea dificile pentru tine? Trebuie numai să apesi butonul pe nivelul dorit în acest loc.',
    },

    tutorialFifthPointText: {
        pl: 'Okno Twojej aktywności pokazuje, jak przebiega Twój proces nauki. Systematyczna nauka sprawi że osiągniesz wymarzone rezultaty jeszcze szybciej.',
        cs: 'Okno s aktivitami vám ukazuje, jak probíhá vaše učení. Pokud budete studovat systematicky, dosáhnete vysněných výsledků ještě rychleji.',
        sk: 'V okne aktivít sa zobrazuje priebeh učenia. Ak budete študovať systematicky, dosiahnete svoje vysnívané výsledky ešte rýchlejšie.',
        ro: 'Fereastra ta de activitate arată cum decurge procesul tău de învățământ. Cu ajutorul învăţării sistematice vei obține rezultatele visate cât mai repede.',
    },

    tutorialSixthPointText: {
        pl: 'W tym miejscu widzisz, jakiego języka aktualnie się uczysz. Jeśli posiadasz dostęp do innych kursów, po kliknięciu w “dalsi kurzy >>” możesz wybrać inny język do nauki.',
        cs: 'Zde se můžete podívat, který jazyk se právě učíte. Pokud máte přístup k dalším kurzům, můžete si vybrat další jazyk, který se chcete naučit, kliknutím na "další kurzy >>".',
        sk: 'Tu môžete vidieť, ktorý jazyk sa práve učíte. Ak máte prístup k iným kurzom, môžete si vybrať ďalší jazyk, ktorý sa chcete naučiť, kliknutím na "ďalšie kurzy >>".',
        ro: 'În acest loc vezi ce limbă înveți în prezent. Dacă ai acces la alte cursuri, după apăsarea pe butonul “ alte cursuri >>” poți alege altă limbă care ai să înveți.',
    },

    tutorialSeventhPointText: {
        pl: 'Chcesz nauczyć się kolejnego języka? Wpisz tutaj kod aktywacyjny nowego języka.',
        cs: 'Chcete se naučit další jazyk? Zde zadejte aktivační kód pro nový jazyk.',
        sk: 'Chcete sa naučiť ďalší jazyk? Tu zadajte aktivačný kód pre nový jazyk.',
        ro: 'Dorești să înveți mai o limbă? Introduce aici codul de activare pentru noua limbă.',
    },

    tutorialEighthPointText: {
        pl: 'Każdego dnia, dowiedz się czegoś nowego! Aby nauka nie stała się nudną rutyną, zebraliśmy dla Ciebie ciekawostki o krajach, których kursy są dostępne na Verbalia.',
        cs: 'Každý den se naučte něco nového! Aby se učení nestalo nudnou rutinou, shromáždili jsme pro vás zajímavosti o zemích, jejichž kurzy jsou na Verbaliau k dispozici.',
        sk: 'Každý deň sa naučte niečo nové! Aby sa učenie nestalo nudnou rutinou, zozbierali sme zaujímavé fakty o krajinách, ktorých kurzy sú dostupné na portáli Verbalia.',
        ro: 'În fiecare zi, află ceva nou! Pentru ca învățământul să nu devină o rutină plictisitoare, am adunat pentru tine informații interesante despre țările ale căror cursuri sunt disponibile pe Verbalia.',
    },

    tutorialNinthPointText: {
        pl: 'Po kliknięciu na symbol 3 poziomych kresek w prawym górnym rogu ekranu, rozwinie się panel użytkownika. Jest to miejsce, w którym uzyskasz dostęp do najważniejszych funkcji, takich jak zmiana hasła, czy wylogowanie. Możesz tu także sprawdzić swoją aktywność, albo uzyskać informacje o kontakcie lub certyfikacie.',
        cs: 'Pokud kliknete na symbol 3 vodorovných čárek v pravém horním rohu obrazovky, rozbalí se uživatelský panel. Zde máte přístup k nejdůležitějším funkcím, jako je změna hesla nebo odhlášení. Zde můžete také zkontrolovat svou aktivitu nebo získat kontaktní informace či certifikát.',
        sk: 'Ak kliknete na symbol 3 vodorovných čiar v pravom hornom rohu obrazovky, používateľský panel sa rozbalí. Tu máte prístup k najdôležitejším funkciám, ako je zmena hesla alebo odhlásenie. Tu si tiež môžete skontrolovať svoju aktivitu alebo získať svoje kontaktné informácie či certifikát.',
        ro: 'După ce vei apăsa buton pe simbolul a 3 linii orizontale din colțul din dreapta sus al ecranului, se va deschide panoul de navigare. Aici vei obține acces la cele mai importante funcții precum schimbarea parolei sau deconectarea. Poți verifica activitatea personal, sau obține informații despre un contact sau un certificat.',
    },

    tutorialPointTitleLesson: {
        pl: 'Lekcja',
        cs: 'Lekce',
        sk: 'Lekcia',
        ro: '',
    },

    tutorialLessonFirstPointText: {
        pl: 'Na samej górze widzisz poziom nauki, tytuł lekcji oraz jej numer',
        cs: 'Úplně nahoře vidíte úroveň učení, název lekce a číslo lekce.',
        sk: 'Úplne hore vidíte úroveň učenia, názov lekcie a číslo lekcie.',
        ro: 'Pe partea care este mai sus poți vedea nivelul de învățământ, titlul și numărul lecției',
    },

    tutorialLessonSecondPointText: {
        pl: 'Każde z zadań zawiera krótkie polecenie, dzięki któremu wiesz, co robić.',
        cs: 'Každý úkol obsahuje krátký příkaz, abyste věděli, co máte udělat.',
        sk: 'Každá úloha obsahuje krátky príkaz, aby ste vedeli, čo máte urobiť.',
        ro: 'Fiecare sarcină are o scurtă comandă care  spune ce trebuie de făcut.',
    },

    tutorialLessonThirdPointText: {
        pl: 'Na samym dole znajduje się Twój pasek postępu. Zauważ, że każdy z jego kolorów oznacza coś innego:',
        cs: 'Úplně dole se nachází ukazatel průběhu. Všimněte si, že každá z jeho barev znamená něco jiného:',
        sk: 'Úplne dole sa nachádza ukazovateľ priebehu. Všimnite si, že každá z jeho farieb znamená niečo iné:',
        ro: 'Pe partea care este mai jos se află bara ta de progres. Reține, că fiecare dintre culorile  înseamnă altceva:',
    },

    tutorialLessonNestedListFirstPointText: {
        pl: 'zielony - dobrze rozwiązane zadanie',
        cs: 'zelený - dobře vyřešený úkol',
        sk: 'zelený - dobre vyriešená úloha',
        ro: 'verde - o sarcină bine rezolvată',
    },

    tutorialLessonNestedListSecondPointText: {
        pl: 'czerwony - źle rozwiązane zadanie (wrócisz do niego pod koniec lekcji)',
        cs: 'červený - špatně vyřešená úloha (vrátíte se k ní na konci lekce).',
        sk: 'červený - zle vyriešená úloha (vrátite sa k nej na konci hodiny)',
        ro: 'roșu - o sarcină incorect rezolvată (vei reveni la ea la sfârșitul lecției)',
    },

    tutorialLessonNestedListThirdPointText: {
        pl: 'żółty - miejsce, w którym aktualnie się znajdujesz',
        cs: 'žlutý - kde se právě nacházíte',
        sk: 'žltý - kde sa práve nachádzate',
        ro: 'galben – unde te afli în acest moment',
    },

    tutorialTenthPointText: {
        pl: 'Na końcu każdego poziomu czeka na Ciebie test, który sprawdzi Twoją wiedzę z zakresu danego poziomu. Aby do niego przystąpić musisz mieć zaliczone wszystkie lekcje z tego poziomu.',
        cs: 'Na konci každé úrovně vás čeká test, který prověří vaše znalosti dané úrovně. Abyste to mohli začít, musíte absolvovat všechny lekce z této úrovně.',
        sk: 'Na konci každej úrovne vás čaká test, ktorý preverí vaše znalosti danej úrovne. Aby ste to mohli začať, musíte absolvovať všetky lekcie z tejto úrovne.e',
        ro: 'La sfârșitul fiecărui nivel, te așteaptă un test pentru a-ți testa cunoștințele despre acel nivel. Pentru a participa la el, trebuie să fi finalizat toate lecțiile de la acest nivel.',
    },

    testLessonScreenText: {
        pl: 'Sprawdź swoją wiedzę z poziomu',
        sk: 'Otestujte si svoje znalosti od úrovne',
        cs: 'Otestujte si své znalosti od úrovně',
        ro: 'Testează-ți cunoștințele de la nivelu',
    },

    testLessonScreenButtonText: {
        pl: 'wypełnij test',
        cs: 'vyplňte test',
        sk: 'vyplňte test',
        ro: 'completează formularul',
    },

    testMainHeader: {
        pl: 'Test wiedzy - poziom',
        cs: 'Test znalostí - úroveň',
        sk: 'Vedomostný test - úroveň',
        ro: 'Test de cunoștințe - nivel',
    },

    testRulesHeader: {
        pl: 'Zasady testu wiedzy',
        cs: 'Pravidla znalostního testu',
        sk: 'Pravidlá znalostného testu',
        ro: 'Hodně štěstí!',
    },

    testWelcome: {
        pl: 'Cześć',
        cs: 'Ahoj',
        sk: 'Ahoj',
        ro: 'Bună',
    },

    testDesc: {
        pl: 'W tym miejscu możesz rozwiązać test wiedzy ze wszystkich poznanych słówek na poziomie {{level}}. Do każdego z zadań masz możliwość podejść tylko jeden raz. Pod koniec testu zostanie wyświetlony Twój wynik. Do testu możesz podchodzić wielokrotnie, by poprawić swój wynik.',
        cs: 'Zde můžete vyřešit znalostní test všech slov, která jste se naučili na úrovni {{level}}. Každý úkol můžete udělat pouze jednou. Na konci testu se zobrazí váš výsledek. Test můžete absolvovat vícekrát, abyste zlepšili své skóre.',
        sk: 'Tu môžete vyriešiť znalostný test všetkých slov, ktoré ste sa naučili na úrovni {{level}}. Každú úlohu môžete urobiť iba raz. Na konci testu sa zobrazí váš výsledok. Test môžete absolvovať viackrát, aby ste zlepšili svoje skóre.',
        ro: 'Aici puteți rezolva testul de cunoștințe al tuturor cuvintelor pe care le-ați învățat la nivelul {{level}}. Puteți face fiecare sarcină o singură dată. La sfârșitul testului, rezultatul dvs. va fi afișat. Puteți susține testul de mai multe ori pentru a vă îmbunătăți scorul.',
    },

    testGoodLuck: {
        pl: 'Powodzenia!',
        cs: 'Hodně štěstí!',
        sk: 'Veľa šťastia!',
        ro: 'Mult noroc!',
    },

    testStartBtn: {
        pl: 'Wypełnij test',
        cs: 'Dokončit test',
        sk: 'Dokončite test',
        ro: 'Finaliza testul',
    },

    testResultHeader: {
        pl: 'Wynik testu',
        cs: 'Výsledek testu',
        sk: 'Výsledok testu',
        ro: 'Rezultatul testului',
    },

    testActivitiesCompleted: {
        pl: 'Ilość dobrze zrobionych zadań:',
        cs: 'Počet dobře provedených úkolů:',
        sk: 'Počet dobre vykonaných úloh:',
        ro: 'Număr de sarcini bine realizate:',
    },

    testRepeatDesc: {
        pl: 'Jeśli chcesz możesz podejść do testu jeszcze raz i poprawić swój wynik.',
        cs: 'Chcete-li, můžete test absolvovat znovu a zlepšit svůj výsledek.',
        sk: 'Ak chcete, môžete test absolvovať znova a zlepšiť svoj výsledok.',
        ro: 'Dacă doriți, puteți să faceți din nou testul și să vă îmbunătățiți rezultatul.',
    },

    testBestScore: {
        pl: 'Twój najlepszy wynik:',
        cs: 'Váš nejlepší výsledek:',
        sk: 'Váš najlepší výsledok:',
        ro: 'Cel mai bun rezultat al tau:',
    },

    testBackBtn: {
        pl: 'Wszystkie lekcje',
        cs: 'Všechny lekce',
        sk: 'Všetky lekcie',
        ro: 'Toate lecțiile',
    },

    testRepeaBtn: {
        pl: 'Wypełnij test ponownie',
        cs: 'Udělat test znovu',
        sk: 'Urobte si test znova',
        ro: 'Face din nou testul',
    },

    askExpert: {
        pl: 'Zapytaj eksperta',
        en: 'Ask the expert',
        cs: 'Zeptejte se odborníka',
        sk: 'Spýtajte sa odborníka',
        ro: 'Întrebați-l pe expert',
        hu: 'Kérdezze a szakértőt',
    },

    askExpertDescription: {
        pl: 'Masz trudności z rozwiązaniem zadania? Zadaj pytanie naszemu ekspertowi. Odpowiedź pojawi się w skrzynce odbiorczej.',
        en: 'Having difficulty solving an assignment? Ask your question to our expert. The answer will appear in your inbox.',
        cs: 'Máte potíže s řešením úkolu? Položte otázku našemu odborníkovi. Odpověď se objeví ve vaší schránce.',
        sk: 'Máte ťažkosti s riešením úlohy? Položte otázku nášmu odborníkovi. Odpoveď sa objaví vo vašej schránke.',
        ro: 'Aveți dificultăți în rezolvarea unei sarcini? Adresați o întrebare expertului nostru. Răspunsul va apărea în căsuța dvs. poștală.',
        hu: 'Nehézséget okoz egy feladat megoldása? Tegye fel kérdését szakértőnknek. A válasz a postaládájába fog érkezni.',
    },

    askExpertSend: {
        pl: 'Wyślij',
        en: 'Send',
        cs: 'Odeslat',
        sk: 'Odoslať',
        ro: 'Trimiteți',
        hu: 'Küldje el',
    },

    askExpertInboxHeader: {
        pl: 'Pytanie do lekcji',
        en: 'Question for the lesson',
        cs: 'Otázka k lekci',
        sk: 'Otázka k lekcii',
        ro: 'Întrebare pentru lecție',
        hu: 'Kérdés a leckéhez',
    },

    askExpertAnswer: {
        pl: 'Odpowiedź eksperta',
        en: 'Answer from the expert',
        cs: 'Odpověď odborníka',
        sk: 'Odpoveď odborníka',
        ro: 'Răspuns de la expert',
        hu: 'A szakértő válasza',
    },

    inbox: {
        pl: 'Skrzynka odbiorcza',
        en: 'Inbox',
        cs: 'Doručená pošta',
        sk: 'Doručená pošta',
        ro: 'Inbox',
        hu: 'Bejövő postaláda',
    },

    makeRateText: {
        pl: 'Oceń kartę:',
        en: 'Rate card:',
        cs: 'Hodnotící karta:',
        sk: 'Hodnotiaca karta:',
        ro: 'Carte de tarife:',
        hu: 'Díjtáblázat:',
    },

    pollHeading: {
        pl: 'Kolejna lekcja za Tobą!',
        en: 'Another lesson has been completed!',
        cs: 'Další lekce je za tebou!',
        sk: 'Ďalšia lekcia je za tebou! ',
        ro: 'O altălecție este în spatele tău!',
        hu: 'Már túl vagy egy újabb leckén!',
    },

    pollFirstParagraph: {
        pl: 'Chcemy poznać Twoją opinię,abyśmy mogli dalej rozwijać kurs i dopasowywać go do Twoich potrzeb. Prosimy o zaznaczenie odpowiedzi.',
        en: 'We are eager to hear your feedback, which enables us to continue refining and tailoring our course to meet your needs. Please, indicate your responses.',
        cs: 'Chceme znát tvůj názor, abychom mohli kurz dále rozvíjet a přizpůsobovat ho tvým potřebám. Prosíme o označeníodpovědi.',
        sk: 'Chceme poznať tvoj názor, aby sme mohli ďalejrozvíjať kurz a prispôsobovať ho tvojim potrebám. Prosíme o označenie odpovede.',
        ro: 'Vrem săștimpărerea ta, pentru a putea dezvolta în continuare cursul și a-l adapta nevoilor tale. Te rugămsă marchezi răspunsul.',
        hu: 'Szeretnénk megismerni a véleményed, hogy tovább fejleszthessük a tanfolyamot és igazíthassuk a te igényeidhez. Kérjük, jelöld be a válaszod.',
    },

    pollSecondParagraph: {
        pl: 'W skali od 1 do 5, gdzie 1 to całkowicie niezadowolony, a 5 to bardzo zadowolony, jak oceniasz nasząaplikację pod kątem:',
        en: 'On a scale from 1 to 5, where 1 represents complete dissatisfaction and 5 signifies great satisfaction, how would you rate our application in terms of:',
        cs: 'Na stupnici od 1 do 5, kde 1 je zcela nespokojený a 5 je velmi spokojený, jak hodnotíš naši aplikaci z hlediska:',
        sk: 'Na stupnici od 1 do 5, kde 1 je úplne nespokojný a 5 je veľmi spokojný, ako hodnotíš našu aplikáciu z hľadiska:',
        ro: 'Pe o scară de la 1 la 5, unde 1 este complet nemulțumitși 5 este foarte mulțumit, cum evaluezi aplicațianoastră în ceea ce privește:',
        hu: '1-től 5-ig terjedő skálán, ahol 1 teljesen elégedetlen, 5 pedig nagyon elégedett, hogyan értékelnéd az alkalmazásunkat a következő szempontok alapján:',
    },

    pollFirstQuestion: {
        pl: 'Jakości treści edukacyjnych',
        en: 'The quality of educational content',
        cs: 'Kvality vzdělávacíchobsahů',
        sk: 'Kvality vzdelávacích obsahov',
        ro: 'Calitatea conținutuluieducațional',
        hu: 'Az oktatási tartalom minősége',
    },

    pollSecondQuestion: {
        pl: 'Jakości audio i zdjęć',
        en: 'The quality of audio and visuals',
        cs: 'Kvality audia a fotografií',
        sk: 'Kvality audia a fotografií',
        ro: 'Calitatea audio și a fotografiilor',
        hu: 'Az audio és a képek minősége',
    },

    pollThirdQuestion: {
        pl: 'Łatwości korzystania z aplikacji',
        en: 'The user-friendliness of the application',
        cs: 'Jednoduchosti používání aplikace',
        sk: 'Lahkosti používania aplikácie',
        ro: 'Ușurința de utilizare a aplicației',
        hu: 'Az alkalmazás könnyű használata',
    },

    pollSecondHeading: {
        pl: 'Ostatnie pytanie',
        en: 'Last question',
        cs: 'Poslední otázka',
        sk: 'Posledná otázka',
        ro: 'Ultima întrebare',
        hu: 'Utolsó kérdés',
    },

    pollFourthQuestion: {
        pl: 'Jakie zmiany w naszej platformie chcesz zobaczyć w przyszłości?',
        en: 'What improvements would you like to see in our platform in the future?',
        cs: 'Jaké změny na naší platformě chceš vidět v budoucnosti?',
        sk: 'Aké zmeny na našej platforme chceš vidieť v budúcnosti?',
        ro: 'Ce schimbări în platforma noastrăți-arplăceasă vezi în viitor?',
        hu: 'Milyen változásokat szeretnél látni a platformunkon a jövőben?',
    },

    pollTextareaPlaceholder: {
        pl: 'Kliknij w okienko, aby rozpocząć pisanie',
        en: 'Click on the box to start typing',
        cs: 'Klepnutím na pole začněte psát',
        sk: 'Kliknutím na políčko začnite písať',
        ro: 'Faceți clic pe casetă pentru a începe să tastați',
        hu: 'Kattintson a mezőre a gépelés megkezdéséhez',
    },

    pollThanksParagraph: {
        pl: 'Dziękujemy za wypełnienie ankiety!',
        en: 'Thank you for taking the time to complete our survey!',
        cs: 'Děkujeme za vyplnění dotazníku!',
        sk: 'Ďakujeme za vyplnenie dotazníka!',
        ro: 'Vămulțumim pentru completarea sondajului!',
        hu: 'Köszönjük, hogy kitöltötted a felmérést!',
    },

    pollThanksSecondParagraph: {
        pl: 'Twoja opinia jest dla nas bardzo cenna w dalszym rozwoju kursu.',
        en: 'Your feedback is incredibly valuable to us as we continue to enhance our course.',
        cs: 'Tvůj názor je pro nás velmi cenný pro další rozvoj kurzu.',
        sk: 'Tvoj názor je pre nás veľmi cenný pri ďalšom rozvoji kurzu.',
        ro: 'Părerea ta este foarte valoroasă pentru noi în dezvoltarea ulterioară a cursului.',
        hu: 'A véleményed nagyon értékes számunkra a tanfolyam továbbfejlesztésében.',
    },

    pollCancelButton: {
        pl: 'Zapytaj później',
        en: 'Ask later',
        cs: 'Zeptejte se později',
        sk: 'Opýtajte sa neskôr',
        ro: 'Intreaba mai tarziu',
        hu: 'Kérdezz később',
    },

    pollNextButton: {
        pl: 'Dalej',
        en: 'Next',
        cs: 'Další',
        sk: 'Ďalšie',
        ro: 'Următorul',
        hu: 'Következő',
    },

    pollSendButton: {
        pl: 'Wyślij',
        en: 'Send',
        cs: 'Poslat',
        sk: 'Odoslať',
        ro: 'Trimite',
        hu: 'Küld',
    },

    passwordRecoveryLoginText: {
        pl: 'Nie pamiętam hasła? ',
        en: "I don't remember the password?",
        cs: 'Nepamatuji si heslo?',
        sk: 'Nepamätám si heslo?',
        ro: 'Nu-mi amintesc parola?',
        hu: 'Nem emlékszem a jelszóra?',
    },

    passwordRecoveryLoginLink: {
        pl: 'Kliknij tutaj',
        en: 'Click here',
        cs: 'Klikněte zde',
        sk: 'Kliknite sem',
        ro: 'Faceți clic aici',
        hu: 'Kattintson ide',
    },

    passwordRecoveryHeader: {
        pl: 'Nie pamiętam hasła',
        en: "I don't remember the password",
        cs: 'Nepamatuji si heslo',
        sk: 'Nepamätám si heslo',
        ro: 'Nu-mi amintesc parola',
        hu: 'Nem emlékszem a jelszóra',
    },

    passwordRecoveryDescription: {
        pl: 'Aby otrzymać link do zresetowania hasła, wprowadź poniżej adres e-mail powiązany z Twoim kontem.',
        en: 'To receive a link to reset your password, enter the email address associated with your account below.',
        cs: 'Abyste obdrželi odkaz na resetování hesla, zadejte níže e-mailovou adresu spojenou s vaším účtem.',
        sk: 'Ak chcete dostať odkaz na obnovenie hesla, zadajte nižšie e-mailovú adresu priradenú k vášmu kontu.',
        ro: 'Pentru a primi un link de resetare a parolei, introduceți adresa de e-mail asociată contului dumneavoastră mai jos.',
        hu: 'Ha szeretne egy linket kapni jelszava visszaállításához, adja meg a fiókjához tartozó e-mail címet az alábbiakban.',
    },

    passwordRecoveryEmail: {
        pl: 'Twój adres e-mail',
        en: 'Your e-mail',
        cs: 'Váš e-mail',
        sk: 'Váš e-mail',
        ro: 'E-mail-ul dvs',
        hu: 'Az Ön e-mail címe',
    },

    passwordRecoveryButton: {
        pl: 'Resetuj hasło',
        en: 'Reset password',
        cs: 'Resetovat heslo',
        sk: 'Obnovenie hesla',
        ro: 'Resetare parolă',
        hu: 'Jelszó visszaállítása',
    },

    passwordRecoveryButtonAfterSent: {
        pl: 'Wiadomość e-mail została wysłana',
        en: 'The email has been sent',
        cs: 'E-mail byl odeslán',
        sk: 'E-mail bol odoslaný',
        ro: 'E-mailul a fost trimis',
        hu: 'Az e-mail elküldésre került',
    },

    changePasswordHeader: {
        pl: 'Wprowadź nowe hasło',
        en: 'Enter a new password',
        cs: 'Zadejte nové heslo',
        sk: 'Zadajte nové heslo',
        ro: 'Introduceți o nouă parolă',
        hu: 'Új jelszó megadása',
    },

    changePasswordDescription: {
        pl: 'Wprowadź nowe hasło tutaj',
        en: 'Enter your new password here',
        cs: 'Zde zadejte své nové heslo',
        sk: 'Zadajte svoje nové heslo tu',
        ro: 'Introduceți noua dvs. parolă aici',
        hu: 'Adja meg új jelszavát itt',
    },

    changePasswordPasswordLabel: {
        pl: 'Nowe hasło',
        en: 'New password',
        cs: 'Nové heslo',
        sk: 'Nové heslo',
        ro: 'Parolă nouă',
        hu: 'Új jelszó',
    },

    changePasswordRepeatPasswordLabel: {
        pl: 'Potwierdź hasło',
        en: 'Confirm password',
        cs: 'Potvrďte heslo',
        sk: 'Potvrdenie hesla',
        ro: 'Confirmați parola',
        hu: 'Jelszó megerősítése',
    },

    changePasswordButton: {
        pl: 'Resetowanie hasła',
        en: 'Reset password',
        cs: 'Resetovat heslo',
        sk: 'Obnovenie hesla',
        ro: 'Resetare parolă',
        hu: 'Jelszó visszaállítása',
    },

    certificateHeader: {
        pl: 'CERTYFIKAT',
        en: 'CERTIFICATE',
        cs: 'CERTIFIKÁT',
        sk: 'CERTIFIKÁT',
        ro: 'CERTIFICAT',
        hu: 'TANÚSÍTVÁNY',
    },

    certificateNotAllowedHeader: {
        pl: 'UKOŃCZ WSZYSTKIE LEKCJE, ABY OTRZYMAĆ CERTYFIKAT',
        en: 'COMPLETE ALL LESSONS TO GET A CERTIFICATE',
        cs: 'DOKONČI VŠECHNY LEKCE, ABYS OBDRŽEL CERTIFIKÁT',
        sk: 'DOKONČI VŠETKY LEKCIE, ABY SI ZÍSKAL CERTIFIKÁT',
        ro: 'FINALIZEAZĂ TOATE LECȚIILE PENTRU A PRIMI CERTIFICATUL',
        hu: 'TÖLTSD KI AZ ÖSSZES LECKÉT, HOGY MEGKAPD A TANÚSÍTVÁNYT',
    },

    certificateDescription: {
        pl: 'Aby otrzymać certyfikat, wpisz swoje imię i nazwisko',
        en: 'To receive a certificate, enter your name and surname',
        cs: 'Chceš-li získat certifikát, zadej své jméno a příjmení',
        sk: 'Ak chceš získať certifikát, zadaj svoje meno a priezvisko',
        ro: 'Pentru a primi certificatul, introdu numele și prenumele',
        hu: 'Hogy megkapd a tanúsítványt, add meg a neved és a vezetékneved',
    },

    certificateName: {
        pl: 'Imię i nazwisko',
        en: 'Name and surname',
        cs: 'Jméno a příjmení',
        sk: 'Meno a priezvisko',
        ro: 'Nume și prenume',
        hu: 'Név és vezetéknév',
    },

    certificateSend: {
        pl: 'Wyślij',
        en: 'Send',
        cs: 'Odeslat',
        sk: 'Odoslať',
        ro: 'Trimite',
        hu: 'Küldés',
    },

    certificateWarning: {
        pl: 'Upewnij się,że wpisujesz prawidłowe dane. Nie ma możliwościpóźniejszej zmiany.',
        en: 'Make sure you enter the correct data. There is no possibility of later change.',
        cs: 'Ujisti se, že zadáváš správné údaje. Není možnost pozdějšízměny.',
        sk: 'Uistite sa, že zadávate správne údaje. Neskôr nie je možné ich zmeniť.',
        ro: 'Asigură-tecă introduci date corecte. Nu va fi posibilă modificarea ulterioară.',
        hu: 'Győződj meg róla, hogy helyes adatokat adtál meg. Később nem lehetséges módosítani.',
    },

    certificateSendInfo: {
        pl: 'Certyfikat zostanie wysłany do 3 dni roboczych na twój adres mailowy. Pobierzesz go także w tym miejscu.',
        en: 'The certificate will be sent within 3 working days to your email address. You can also download it here.',
        cs: 'Certifikát bude odeslán do 3 pracovních dnů na tvou emailovou adresu. Můžeš si ho také stáhnout na tomto místě.',
        sk: 'Certifikát bude odoslaný do 3 pracovných dní na tvoju emailovú adresu. Môžeš ho stiahnuť aj na tomto mieste.',
        ro: 'Certificatul va fi trimis în 3 zile lucrătoare la adresa ta de e-mail. De asemenea, îl poțidescărcași de aici.',
        hu: 'A tanúsítványt 3 munkanapon belül elküldjük az e-mail címedre. Letöltheted innen is.',
    },

    certificateDownload: {
        pl: 'Pobierz',
        en: 'Download',
        cs: 'Stáhnout',
        sk: 'Stiahnuť',
        ro: 'Descarcă',
        hu: 'Letöltés',
    },

    task: {
        pl: 'Zadanie',
        en: 'Task',
        cs: 'Úkol',
        sk: 'Úloha',
        ro: 'Sarcină',
        hu: 'Feladat',
    },

    taskActivityTitle: {
        pl: 'Sprawdźmy Twoją wiedzę z ostatnich lekcji.',

        en: "Let's check your knowledge from the last lessons.",
        cs: 'Zkontrolujme si tvé znalosti z posledních lekcí.',
        sk: 'Skontrolujme si tvoje znalosti z posledných lekcií.',
        ro: 'Să verificăm cunoștințele tale din ultimele lecții.',
        hu: 'Nézzük át a legutóbbi leckékben tanultakat.',
    },

    taskActivityDescription: {
        pl: ' Nasz ekspert językowy sprawdzi twoją znajomość języka i wskaże, co możesz jeszcze poprawić.',
        en: 'Our language expert will check your knowledge and point out what you can improve.',
        cs: 'Náš jazykový expert zkontroluje tvé znalosti a ukáže ti, co můžeš ještě zlepšit.',
        sk: 'Náš jazykový expert skontroluje tvoje znalosti a ukáže ti, čo môžeš ešte zlepšiť.',
        ro: 'Expertul nostru lingvistic îți va verifica cunoștințele și îți va arăta ce poți îmbunătăți.',
        hu: 'Nyelvi szakértőnk átnézi a tudásod és megmutatja, mit tudsz még fejleszteni.',
    },

    taskActivitySecondDescription: {
        pl: 'Wiadomość zwrotna pojawi się w skrzynce odbiorczej.',
        en: 'The feedback will appear in your inbox.',
        cs: 'Zpětná vazba se objeví v tvé schránce.',
        sk: 'Spätná väzba sa objaví v tvojej schránke.',
        ro: 'Feedback-ul va apărea în inbox-ul tău.',
        hu: 'A visszajelzés megjelenik a beérkezett üzeneteid között.',
    },

    taskActivityName: {
        pl: 'Zadanie:',
        en: 'Task:',
        cs: 'Úkol:',
        sk: 'Úloha:',
        ro: 'Sarcină:',
        hu: 'Feladat:',
    },

    taskActivityDone: {
        pl: 'Zrobione',
        en: 'Done',
        cs: 'Hotovo',
        sk: 'Hotovo',
        ro: 'Gata',
        hu: 'Kész',
    },

    taskActivityExample: {
        pl: 'Przykładowa odpowiedź',
        en: 'Example answer',
        cs: 'Příklad odpovědi',
        sk: 'Príklad odpovede',
        ro: 'Exemplu de răspuns',
        hu: 'Példaválasz',
    },

    taskActivitySkip: {
        pl: 'Pomiń',
        en: 'Skip',
        cs: 'Přeskočit',
        sk: 'Preskočiť',
        ro: 'Omite',
        hu: 'Kihagy',
    },

    taskActivitySend: {
        pl: 'Wyślij',
        en: 'Send',
        cs: 'Odeslat',
        sk: 'Odoslať',
        ro: 'Trimite',
        hu: 'Küldés',
    },

    taskActivityMessageSent: {
        pl: 'Wiadomość pomyślnie wysłano',
        en: 'Message sent successfully',
        cs: 'Zpráva byla úspěšně odeslána',
        sk: 'Správa bola úspešne odoslaná',
        ro: 'Mesajul a fost trimis cu succes',
        hu: 'Az üzenet sikeresen elküldve',
    },

    taskActivityMessageSentDescription: {
        pl: 'Odpowiedź pojawi się w skrzynce odbiorczej.',
        en: 'The answer will appear in your inbox.',
        cs: 'Odpověď se objeví v tvé schránce.',
        sk: 'Odpoveď sa objaví v tvojej schránke.',
        ro: 'Răspunsul va apărea în inbox-ul tău.',
        hu: 'A válasz megjelenik a beérkezett üzeneteid között.',
    },

    expert: {
        pl: 'Ekspert',
        en: 'Expert',
        cs: 'Expert',
        sk: 'Expert',
        ro: 'Expert',
        hu: 'Szakértő',
    },

    descriptiveTask: {
        pl: 'Zadanie opisowe',
        en: 'Descriptive task',
        cs: 'Popisný úkol',
        sk: 'Popisná úloha',
        ro: 'Sarcină descriptivă',
        hu: 'Leíró feladat',
    },

    inboxHeader: {
        pl: 'Skrzynka odbiorcza',
        en: 'Inbox',
        cs: 'Doručená pošta',
        sk: 'Doručená pošta',
        ro: 'Inbox',
        hu: 'Beérkezett üzenetek',
    },

    inboxSender: {
        pl: 'Nadawca',
        en: 'Sender',
        cs: 'Odesílatel',
        sk: 'Odosielateľ',
        ro: 'Expeditor',
        hu: 'Küldő',
    },

    inboxSubject: {
        pl: 'Temat',
        en: 'Subject',
        cs: 'Předmět',
        sk: 'Predmet',
        ro: 'Subiect',
        hu: 'Tárgy',
    },

    inboxDate: {
        pl: 'Data',
        en: 'Date',
        cs: 'Datum',
        sk: 'Dátum',
        ro: 'Data',
        hu: 'Dátum',
    },

    inboxResponse: {
        pl: 'Odpowiedź',
        en: 'Response',
        cs: 'Odpověď',
        sk: 'Odpoveď',
        ro: 'Răspuns',
        hu: 'Válasz',
    },

    inboxTypeMessage: {
        pl: 'wpisz wiadomość...',
        en: 'type message...',
        cs: 'napiš zprávu...',
        sk: 'napíš správu...',
        ro: 'scrie mesajul...',
        hu: 'írd be az üzenetet...',
    },

    inboxSend: {
        pl: 'Wyślij',
        en: 'Send',
        cs: 'Odeslat',
        sk: 'Odoslať',
        ro: 'Trimite',
        hu: 'Küldés',
    },

    inboxAskExpert: {
        pl: 'Zapyaj eksperta',
        en: 'Ask expert',
        cs: 'Zeptej se experta',
        sk: 'Opýtaj sa experta',
        ro: 'Întreabă expertul',
        hu: 'Kérdezd meg a szakértőt',
    },

    inboxTask: {
        pl: 'Zadanie',
        en: 'Task',
        cs: 'Úkol',
        sk: 'Úloha',
        ro: 'Sarcină',
        hu: 'Feladat',
    },

    transcriptionTrigger: {
        pl: 'Kliknij, aby przeczytać słowo.',
        cs: 'Klikněte pro přečtení slova.',
        sk: 'Kliknutím si prečítajte slovo.',
        en: 'Click to read the word.',
        hu: 'Kattints a szó elolvasásához.',
        ro: 'Click pentru a citi cuvântul.',
    },
    endModalHeader: {
      pl: 'Szanowni klienci,',
      cs: 'Vážení zákazníci,',
      sk: 'Vážení zákazníci,',
      en: 'Dear customers,',
      hu: 'Tisztelt ügyfeleink,',
      ro: 'Stimați clienți,',
  },
  endModalParagraph1: {
      pl: 'z powodu likwidacji naszej firmy chcielibyśmy poinformować Państwa o szczegółach działania naszych usług w najbliższej przyszłości.',
      cs: 'z důvodu likvidace naší společnosti bychom Vás rádi informovali o podrobnostech provozu našich služeb v blízké budoucnosti.',
      sk: 'z dôvodu likvidácie našej spoločnosti by sme Vás radi informovali o podrobnostiach prevádzky našich služieb v blízkej budúcnosti.',
      en: 'due to the liquidation of our company, we would like to inform you about the details of the operation of our services in the near future.',
      hu: 'cégünk felszámolása miatt szeretnénk tájékoztatni Önöket szolgáltatásaink működésének részleteiről a közeljövőben.',
      ro: 'datorită lichidării companiei noastre, dorim să vă informăm cu privire la detaliile funcționării serviciilor noastre în viitorul apropiat.',
  },
  endModalParagraph2: {
      pl: 'Dział obsługi klienta będzie dostępny do 21.03.2025 pod adresem kzs@verbalia.eu.',
      cs: 'Zákaznické oddělení bude k dispozici do 21.03.2025  na adrese kzs@verbalia.eu.',
      sk: 'Oddelenie služieb zákazníkom bude k dispozícii do 21.03.2025 na adrese ksz@verbalia.eu.',
      en: 'The customer service department will be available until 21.03.2025 at kzs@verbalia.eu.',
      hu: 'Az ügyfélszolgálati osztály 2025.02.14-ig áll rendelkezésre a következő címen: kzs@verbalia.eu.',
      ro: 'Departamentul de servicii pentru clienți va fi disponibil până la 21.03.2025 la bsc@verbalia.eu.',
  },
  endModalParagraph3: {
      pl: 'Platforma e-learningowa będzie aktywna do 9.03.2025.',
      cs: 'E-learningová platforma bude aktivní do 9.03.2025.',
      sk: 'Platforma elektronického vzdelávania bude aktívna do 9.03.2025.',
      en: 'The e-learning platform will be active until 9.03.2025.',
      hu: 'Az e-learning platform 2025.01.31-ig lesz elérhető.',
      ro: 'Platforma de e-learning va fi activă până la 9.03.2025.',
  },
  close: {
      pl: 'Zamknij',
      cs: 'Zavřít',
      sk: 'Zavrieť',
      en: 'Close',
      hu: 'Bezárás',
      ro: 'Închide',
  },
};
